import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home'),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('@/views/index'),
  },
  /**
   * 就诊人管理
   */
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/account/index'),
  },
  {
    path: '/addAccount',
    name: 'AddAccount',
    component: () => import('@/views/account/add'),
  },
  /**
   * 医生排班
   */
  {
    path: '/schedule',
    name: 'Schedule',
    component: () => import('@/views/schedule/index'),
  },
  {
    path: '/scheduleDetail',
    name: 'ScheduleDetail',
    component: () => import('@/views/schedule/detail'),
  },
  {
    path: '/scheduleNumber',
    name: 'scheduleNumber',
    component: () => import('@/views/schedule/number'),
  },
  /**
   * 预约信息
   */
  {
    path: '/appointment',
    name: 'Appointment',
    component: () => import('@/views/appointment'),
  },
  {
    path: '/appointmentConfirm',
    name: 'AppointmentConfirm',
    component: () => import('@/views/appointment/confirm'),
  },
  {
    path: '/appointmentCancel',
    name: 'AppointmentCancel',
    component: () => import('@/views/appointment/cancel'),
  },
  {
    path: '/message',
    name: 'Message',
    component: () => import('@/views/message'),
  },
  /**
   *  门诊服务
   **/
  {
    path: '/queue',
    name: 'Queue',
    component: () => import('@/views/outpatient/queue'),
  },
  {
    path: '/outpatientSchedule',
    name: 'OutpatientSchedule',
    component: () => import('@/views/outpatient/schedule'),
  },
  /**
   * 检验检查
   */
  {
    path: '/inspect',
    name: 'Inspect',
    component: () => import('@/views/inspect/inspect'),
  },
  {
    path: '/inspectInfo',
    name: 'InspectInfo',
    component: () => import('@/views/inspect/inspectInfo'),
  },
  {
    path: '/inspectWSWInfo',
    name: 'InspectWSWInfo',
    component: () => import('@/views/inspect/inspectWSWInfo'),
  },
  /**
   *  个人中心
   **/
  {
    path: '/hospitalFee',
    name: 'HospitalFee',
    component: () => import('@/views/person/hospitalFee'),
  },
  {
    path: '/pay',
    name: 'Pay',
    component: () => import('@/views/person/pay'),
  },
  {
    path: '/preFee',
    name: 'PreFee',
    component: () => import('@/views/person/preFee'),
  },
  {
    path: '/seeDoctor',
    name: 'SeeDoctor',
    component: () => import('@/views/person/seeDoctor'),
  },
  /**
   *  综合服务
   **/
  {
    path: '/doctorClose',
    name: 'DoctorClose',
    component: () => import('@/views/service/doctorClose'),
  },
  {
    path: '/drug',
    name: 'Drug',
    component: () => import('@/views/service/drug'),
  },
  {
    path: '/project',
    name: 'Project',
    component: () => import('@/views/service/project'),
  },
  /**
   * 问卷调查
   */
  {
    path: '/survey',
    name: 'Survey',
    component: () => import('@/views/survey/question'),
  },
  /**
   *病历复印
   */
  {
    path: '/caseCopyAdd',
    name: 'CaseCopyAdd',
    component: () => import('@/views/caseCopy/caseCopyAdd'),
  },
  {
    path: '/caseCopy',
    name: 'CaseCopy',
    component: () => import('@/views/caseCopy/caseCopy'),
  },
  {
    path: '/caseCopyInfo',
    name: 'CaseCopyInfo',
    component: () => import('@/views/caseCopy/caseCopyInfo'),
  },
  /**
   * 床位预约
   */
  {
    path: '/bed',
    name: 'Bed',
    component: () => import('@/views/bed/index'),
  },
  {
    path: '/bedConfirm',
    name: 'BedConfirm',
    component: () => import('@/views/bed/confirm'),
  },
  /**
   * 第三方页
   */
  {
    path: '/yjk',
    name: 'YJK',
    component: () => import('@/views/other/yjk'),
  },
  /**
   * 测试
   */
  {
    path: '/test',
    name: 'Test',
    component: () => import('@/views/test'),
  },
  {
    path: '/money',
    name: 'Money',
    component: () => import('@/views/test/pay'),
  },
]

const router = new VueRouter({
  routes,
})

//路由导航守卫
/*
router.beforeEach(async (to, from, next) => {
  if (to.path === "/login") return next();
  let hasToken = store.getters["user/token"];
  if (!hasToken) {
    return next("/login");
  }
  await store.dispatch("user/getUserInfo");
  next();
});
*/
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

export default router
