import { request } from '@/util/request'
import { loginRSA, publicKey } from '@/config'
import { JSEncrypt } from 'jsencrypt'

export async function getTokenByThird(params) {
  let data = {
    Ident: '',
    Level: 1,
  }
  let encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey)
  data.Ident = encryptor.encrypt(params.ident)
  data.Level = encryptor.encrypt(params.level)
  return request({
    url: '/api/Login/GetTokenByThird',
    method: 'post',
    data,
  })
}

export async function getRefreshToken(params) {
  return request({
    url: '/api/Login/RefreshToken/RefreshToken',
    method: 'get',
    params,
  })
}
