/**
 * @description 导出通用配置
 */
module.exports = {
  tokenName: 'Authorization',
  // token在localStorage、sessionStorage、cookie存储的key的名称
  tokenTableName: 'admin-pro-token',
  // token的有效时间
  expires_in: 'expires_in',
  // 用户信息
  userInfo: 'userInfo',
  // token存储位置localStorage sessionStorage cookie
  storage: 'sessionStorage',
  //openid
  openId: 'openId',
  // 是否开启登录RSA加密
  loginRSA: true,
  // 加密公钥
  publicKey:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAugmNdlI8P/IdBsviyKjWOa1PFq9TYaJ4JSpHL8KSsEOA/pCFasTsnIBaxT2WItXKv4L1RN8+JkUKiNyVtLm0OTZHwYCN4UtIdOLNLhrgfi5U08ercrr7cy4R3rCxaVsiYp/mWLxDdF17WYMPtEytrRE5WSQkOTG/6HAs9iTWlU77JCy7+35mlsYXQ1tL/neZfIoeJOJTDNc/CphlC+vUUBeXvF5/yCcP5UUOU5lZ6Mf+nlFKWxNLIRUUeiRtco6hs4tng+EHtNVnnaY7TDF0JJHXPJepaqlt0PLIE26REcSrU23qw7R48i/I3e9nFYBgXEqOqF2ZOlufRuBgQYQt4wIDAQAB',
  fileType:
    '.doc, .docx, .xls, .xlsx, .pdf, image/*, .rar, zip, .7z, ppt, pptx, .txt',
  // 网页授权地址
  authorizeUrl: 'https://open.weixin.qq.com/connect/oauth2/authorize',
  // APPID
  appID: 'wx86b989899ed775d3',
  // 重定向地址（需跟微信公众号后台配置一致）
  redirectUrl: 'http://wxfwh.yyfybjy.cn',
  // jsApiList
  jsApiList: ['closeWindow', 'chooseWXPay'],
  //确认预约提示
  confirmMessage: '您确认要预约当前号源吗？',
  //取消预约提示
  cancelMessage: '您确定要取消预约当前号源吗？',
  //新增就诊人提示
  addAccountMessage: '请先点击更改就诊人按钮，新增就诊人后进行预约！',
}
