import { getTokenByThird } from '@/api/com/login/login'
import { login } from '@/api/login'
import {
  getToken,
  getUserInfo,
  getExpressIn,
  getOpenId,
  setToken,
  setUserInfo,
  setExpressIn,
  setOpenId,
} from '@/util/token'
import store from '@/store'

const state = () => ({
  token: getToken(),
  express_in: getExpressIn(),
  userInfo: getUserInfo(),
  openId: getOpenId(),
})

const getters = {
  token: (state) => state.token,
  express_in: (state) => state.express_in,
  userInfo: (state) => state.userInfo,
  openId: (state) => state.openId,
}
const mutations = {
  setToken(state, token) {
    state.token = token
    setToken(token)
  },
  setExpressIn(state, express_in) {
    state.express_in = express_in
    setExpressIn(express_in)
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo
    setUserInfo(userInfo)
  },
  setOpenId(state, openId) {
    state.openId = openId
    setOpenId(openId)
  },
}
const actions = {
  /**
   * @description 登录
   * @param {*} { commit }
   * @param {*} userInfo
   */
  async login({ commit }, userInfo) {
    const { success, data } = await getTokenByThird(userInfo)
    if (success) {
      if (data.success) {
        const token = data.token
        const express_in = {
          expires_in: data.expires_in,
          time: Date.now(),
        }
        commit('setToken', token)
        commit('setExpressIn', JSON.stringify(express_in))
      }
    } else {
      return Promise.reject()
    }
  },

  /**
   * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
   * @param {*} { commit, dispatch, state }
   * @returns
   */
  async getUserInfo({ commit }, userInfo) {
    localStorage.removeItem('userInfo')
    if (userInfo) commit('setUserInfo', userInfo)
  },

  /**
   * @description 设置token
   * @param {*} state
   * @param {*} token
   */
  setToken({ commit }, token) {
    commit('setToken', token)
  },
  /**
   * @description 设置token
   * @param {*} state
   * @param {*} express_in
   */
  setExpressIn({ commit }, express_in) {
    commit('setExpressIn', express_in)
  },
  /**
   * @description 设置用户信息
   * @param {*} state
   * @param {*} userInfo
   */
  setUserInfo({ commit }, userInfo) {
    commit('setUserInfo', userInfo)
  },
  /**
   * @description 设置OPENID
   * @param {*} state
   * @param {*} userInfo
   */
  setOpenId({ commit }, openId) {
    commit('setOpenId', openId)
  },
}

export default { state, getters, mutations, actions }
